import styled from 'styled-components';

export const StyledContractObligation = styled.div`
  min-height: calc(100vh - 235px);
  ${(props): string => props.theme.fontStyles.p1.news};

  .contract-obligation__content {
    width: 560px;
    margin: 32px auto;
  }
`;
