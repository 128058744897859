import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { DownloadHeader } from '../../../../components/DownloadHeader/DownloadHeader';
import { selectUserCountryCode } from '../../../../cross-cutting-concerns/authentication/state/authenticationSelectors';
import { Translations } from '../../../../cross-cutting-concerns/translations/Translations';
import { TermsConditionsUtils } from '../../utils/TermsConditionsUtils';
import { ContractObligationContent } from '../ContractObligationContent/ContractObligationContent';
import { StyledContractObligation } from './ContractObligation.styles';

export const ContractObligation = (): JSX.Element => {
  const { t } = useTranslation();
  const userCountryCode = useSelector(selectUserCountryCode);
  const country = Translations.getCountryByCountryCode(userCountryCode);
  const filePath = TermsConditionsUtils.getContractObligationFilePathByCountry(country);

  return (
    <StyledContractObligation>
      <DownloadHeader title={t('common.contractObligation')} href={filePath} />
      <div className="contract-obligation__content">
        <ContractObligationContent />
      </div>
    </StyledContractObligation>
  );
};
